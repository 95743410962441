var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "no-padding", attrs: { slot: "list" }, slot: "list" },
    [
      _c(
        "v-card-text",
        [
          _c(
            "v-form",
            {
              ref: "form",
              attrs: { "lazy-validation": "" },
              model: {
                value: _vm.valid,
                callback: function ($$v) {
                  _vm.valid = $$v
                },
                expression: "valid",
              },
            },
            [
              _c(
                "v-layout",
                { attrs: { wrap: "", row: "" } },
                [
                  _c(
                    "v-flex",
                    { attrs: { xs4: "" } },
                    [
                      _c(
                        "v-layout",
                        { attrs: { column: "" } },
                        [
                          _c("v-checkbox", {
                            attrs: {
                              color: "primary",
                              label: "Gerador",
                              value: "generator",
                              disabled: !_vm.edit,
                            },
                            model: {
                              value: _vm.formData.profile,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "profile", $$v)
                              },
                              expression: "formData.profile",
                            },
                          }),
                          _c("v-checkbox", {
                            attrs: {
                              color: "primary",
                              label: "Destinador",
                              value: "receiver",
                              disabled: !_vm.edit,
                            },
                            model: {
                              value: _vm.formData.profile,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "profile", $$v)
                              },
                              expression: "formData.profile",
                            },
                          }),
                          _c("v-checkbox", {
                            attrs: {
                              color: "primary",
                              label: "Transportador",
                              value: "transporter",
                              disabled: !_vm.edit,
                            },
                            model: {
                              value: _vm.formData.profile,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "profile", $$v)
                              },
                              expression: "formData.profile",
                            },
                          }),
                          _c("v-checkbox", {
                            attrs: {
                              color: "primary",
                              label: "Armazenador Temporário",
                              value: "temporary_storage",
                              disabled: !_vm.edit,
                            },
                            model: {
                              value: _vm.formData.profile,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "profile", $$v)
                              },
                              expression: "formData.profile",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xs4: "" } },
                    [
                      _c(
                        "v-layout",
                        { staticClass: "mr-4", attrs: { column: "" } },
                        [
                          _c(
                            "v-flex",
                            { attrs: { xs4: "" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  label: "Nome",
                                  required: "",
                                  disabled: !(_vm.reprovedEntity && _vm.edit),
                                  rules: [_vm.validationRules.required],
                                },
                                model: {
                                  value: _vm.formData.name,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formData, "name", $$v)
                                  },
                                  expression: "formData.name",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            { attrs: { xs4: "" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  mask: _vm.getMask(_vm.formData.cpf_cnpj),
                                  label: "CPF ou CNPJ",
                                  disabled: !(_vm.reprovedEntity && _vm.edit),
                                  required: "",
                                  rules: _vm.getValidationRulesForCpfCnpj(
                                    _vm.formData.cpf_cnpj
                                  ),
                                },
                                model: {
                                  value: _vm.formData.cpf_cnpj,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formData, "cpf_cnpj", $$v)
                                  },
                                  expression: "formData.cpf_cnpj",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            { attrs: { xs4: "" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  mask: "(##) # ####-####",
                                  label: "Celular",
                                  required: "",
                                  disabled: !_vm.edit,
                                  rules: [_vm.validationRules.required],
                                },
                                model: {
                                  value: _vm.formData.cellphone,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formData,
                                      "cellphone",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "formData.cellphone",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xs4: "" } },
                    [
                      _c(
                        "v-layout",
                        { staticClass: "pl-2", attrs: { column: "" } },
                        [_c("logo-uploader")],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-card-actions",
        [
          _c("v-spacer"),
          _vm.edit
            ? _c(
                "v-btn",
                {
                  attrs: { color: "primary", disabled: !_vm.valid },
                  on: {
                    click: function ($event) {
                      _vm.edit = false
                    },
                  },
                },
                [_vm._v("\n      Salvar\n    ")]
              )
            : _vm._e(),
          _vm.edit
            ? _c("v-btn", { on: { click: _vm.cancel } }, [
                _vm._v("\n      Cancelar\n    "),
              ])
            : _vm._e(),
          _vm.canEdit
            ? _c(
                "v-btn",
                {
                  attrs: { color: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.edit = !_vm.edit
                    },
                  },
                },
                [_vm._v("\n      Editar\n    ")]
              )
            : _vm._e(),
          _vm.formWasEdited
            ? _c(
                "v-btn",
                {
                  attrs: {
                    color: "success",
                    disabled: _vm.edit || !_vm.valid,
                    loading: _vm.loading,
                  },
                  on: { click: _vm.submit },
                },
                [_vm._v("\n      Submeter\n    ")]
              )
            : _vm._e(),
          _c("v-spacer"),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }