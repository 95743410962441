<template>
  <v-container fluid :class="{'pa-0': !isCompleteInfoComponent}">
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-layout justify-start>
        <v-flex xs12>
          <header class="headline">
            <h5 class="title">
              <v-icon class="mr-2">mdi-image-outline</v-icon> Logo da Empresa
            </h5>
          </header>
          <div v-if="person.logo.url !== null" class="text-xs-center">
            <img :src="person.logo.url" alt="">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn
                  color="red"
                  small
                  icon
                  flat
                  class="remove-logo"
                  v-on="on"
                  @click="destroyLogo()"
                >
                  <v-icon>
                    mdi-close-circle-outline
                  </v-icon>
                </v-btn>
              </template>
              <span>Remover Logo</span>
            </v-tooltip>
          </div>

          <v-card v-else class="mb-5">
            <v-container grid-list-lg>
              <v-layout
                row
                align-center
              >
                <v-flex xs12>
                  <file-upload
                    label="Arquivo de logo"
                    :allowedExtensions="['png', 'jpg']"
                    extensionErrorMessage="Apenas os formatos .PNG ou .JPG são permitidos"
                    :isUploadingFile="isUploadingFile"
                    :uploadError="uploadError"
                    :file.sync="logo"
                    :filename="title"
                    :uploadFunction="uploadFile"
                    :removeFunction="removeFile">
                  </file-upload>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card>
        </v-flex>
      </v-layout>

    </v-form>
  </v-container>
</template>

<script>
import validationRules from '@/mixins/validation-rules';
import fileUpload from '@/components/base/FileUpload.vue';
import userService from '@/services/user-service';
import { get } from 'lodash';

const fileInitialState = {
  name: '',
  content: '',
  progress: '',
  size: 0,
};

export default {
  name: 'logo-uploader',
  props: {
    step: {
      default: '4',
    },
    title: {
      default: 'Logo da empresa',
    },
    isCompleteInfoComponent: {
      default: false,
    },
  },
  components: { fileUpload },
  data() {
    return {
      logo: '',
      showDatePicker: false,
      picker: null,
      date: null,
      dateFormatted: null,
      org: '',
      file: fileInitialState,
      valid: false,
      validationRules,
      isUploadingFile: false,
      uploadError: '',
    };
  },
  created() {
    this.logo = get(this.currentUser, 'person.logo');
  },
  computed: {
    fileHint() {
      if (this.file.size > 0) {
        return `Tamanho total: ${this.file.size}`;
      }
      return '';
    },
    currentUser() {
      return this.$store.getters['auth/currentUser'];
    },
    person() {
      return this.$store.getters['person/person'];
    },
  },
  methods: {
    uploadFile(fileToUpload) {
      this.isUploadingFile = true;
      this.uploadError = '';
      userService.uploadLogo(fileToUpload, this.person.id)
        .then(({ logo }) => {
          this.updatePersonLogo(logo.url);
        })
        .catch((err) => {
          this.uploadError = 'Erro ao realizar o upload do logo';
          this.onUpdateError(err);
        })
        .finally(() => {
          this.isUploadingFile = false;
        });
    },
    destroyLogo() {
      userService
        .destroyLogo(this.person.id)
        .then(() => {
          this.$store.dispatch('person/setLogo', null);
          this.onUpdateSuccess('Logo removido com Sucesso');
        })
        .catch((err) => {
          console.log(err);
          this.onUpdateError({ message: 'Erro ao Remover o Logotipo da Empresa' });
        });
    },
    async updatePersonLogo(url) {
      this.$store.dispatch('person/setLogo', url);
    },
    goToNextStep() {
      this.updatePersonLogo()
        .then(() => {
          this.$router.push('/');
          this.onUpdateSuccess();
        })
        .catch((err) => {
          this.onUpdateError(err);
        });
    },
    onUpdateSuccess(message) {
      this.$store.dispatch('application/toggleSnackbar', {
        show: true,
        timeout: 6000,
        color: 'success',
        message: (message || 'Usuário atualizado com sucesso!'),
        messageIcon: 'mdi-check',
      });
    },
    onUpdateError(err) {
      this.$store.dispatch('application/toggleSnackbar', {
        show: true,
        timeout: 6000,
        color: 'error',
        message: err.message,
        messageIcon: 'mdi-alert-outline',
      });
    },
    goToPreviousStep() {
      this.$store.dispatch('application/changeStep', Number(this.step) - 1);
    },
    removeFile() {
      this.logo = '';
      this.$emit('update:logo', '');
    },
  },
};
</script>

<style scoped>
.file-uploader-container {
  position: relative;
  display: flex;
  align-items: center;
}
.file-uploader {
  position: absolute;
  z-index: 1;
  width: 190px;
  height: 45px;
  opacity: 0;
  cursor: pointer;
}

.remove-logo{
  top: -25px;
}
</style>
