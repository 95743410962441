<template>
  <v-container slot="list" class="no-padding">
    <v-card-text>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-layout wrap row>
          <v-flex xs4>
            <v-layout column>
              <v-checkbox
                color="primary"
                v-model="formData.profile"
                label="Gerador"
                value="generator"
                :disabled="!edit"
              />
              <v-checkbox
                color="primary"
                v-model="formData.profile"
                label="Destinador"
                value="receiver"
                :disabled="!edit"
              />
              <v-checkbox
                color="primary"
                v-model="formData.profile"
                label="Transportador"
                value="transporter"
                :disabled="!edit"
              />
              <v-checkbox
                color="primary"
                v-model="formData.profile"
                label="Armazenador Temporário"
                value="temporary_storage"
                :disabled="!edit"
              />
            </v-layout>
          </v-flex>
          <v-flex xs4>
            <v-layout column class="mr-4">
              <v-flex xs4>
                <v-text-field
                  v-model="formData.name"
                  label="Nome"
                  required
                  :disabled="!(reprovedEntity && edit)"
                  :rules="[validationRules.required]"
                />
              </v-flex>
              <v-flex xs4>
                <v-text-field
                  :mask="getMask(formData.cpf_cnpj)"
                  v-model="formData.cpf_cnpj"
                  label="CPF ou CNPJ"
                  :disabled="!(reprovedEntity && edit)"
                  required
                  :rules="getValidationRulesForCpfCnpj(formData.cpf_cnpj)"
                />
              </v-flex>
              <v-flex xs4>
                <v-text-field
                  v-model.trim="formData.cellphone"
                  mask="(##) # ####-####"
                  label="Celular"
                  required
                  :disabled="!edit"
                  :rules="[validationRules.required]"
                />
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs4>
            <v-layout column class="pl-2">
              <logo-uploader/>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer/>
      <v-btn
        v-if="edit"
        color="primary"
        :disabled="!valid"
        @click="edit = false"
      >
        Salvar
      </v-btn>
      <v-btn
        v-if="edit"
        @click="cancel"
      >
        Cancelar
      </v-btn>
      <v-btn
        color="primary"
        v-if="canEdit"
        @click="edit = !edit"
      >
        Editar
      </v-btn>
      <v-btn
        color="success"
        v-if="formWasEdited"
        :disabled="edit || !valid"
        :loading="loading"
        @click="submit"
      >
        Submeter
      </v-btn>
      <v-spacer/>
    </v-card-actions>
  </v-container>
</template>

<script>
import { pick, isEqual } from 'lodash';
import validationRules from '@/mixins/validation-rules';
import LogoUploader from '@/components/person/LogoUploader.vue';
import personService from '@/services/person-service';
import authService from '../../services/auth-service';

export default {
  name: 'PersonInfo',
  props: ['selectedEntity'],
  components: {
    LogoUploader,

  },
  data() {
    return {
      pick,
      isEqual,
      loading: false,
      valid: false,
      validationRules,
      edit: false,
      formData: {
        profiles: [],
        name: '',
        cpf_cnpj: '',
        cellphone: '',
      },
    };
  },
  methods: {
    getMask(cpfCnpj) {
      return cpfCnpj.length === 11 ? '###.###.###-##' : '##.###.###/####-##';
    },
    getValidationRulesForCpfCnpj(cpfCnpj) {
      const rules = [];
      rules.push(cpfCnpj.length <= 11 ? validationRules.cpf : validationRules.cnpj);
      return rules;
    },
    checkEntityStatus() {
      if (this.reprovedEntity) {
        this.formData.status = 'waiting_approval';
      }
    },
    updateEntityStatus() {
      if (this.reprovedEntity) {
        this.selectedEntity.status = 'waiting_approval';
      }
    },
    submit() {
      this.checkEntityStatus();

      personService
        .updatePerson(this.person.id, this.formData)
        .then(() => {
          this.updateEntityStatus();
          this.refreshToken();
          this.$store.dispatch('application/toggleSnackbar', {
            show: true,
            timeout: 6000,
            color: 'success',
            message: 'Dados da Pessoa atualizado com Sucesso',
            messageIcon: 'mdi-ckeck',
          });
        })
        .catch((error) => {
          this.$store.dispatch('application/toggleSnackbar', {
            show: true,
            timeout: 6000,
            color: 'error',
            message: error.response.data.join(', '),
            messageIcon: 'mdi-exclamation',
          });
        });
    },
    loadPersonData() {
      this.formData = this.pick(this.person, ['profile', 'name', 'cpf_cnpj', 'cellphone']);
    },
    cancel() {
      this.loadPersonData();
      this.edit = false;
    },
    async refreshToken() {
      const res = await authService.refreshToken();
      const { token } = res;
      const tokenData = this.$jwt.decode(token);
      localStorage.setItem('token', token);
      localStorage.setItem('tokenData', JSON.stringify(tokenData.payload));
      this.$store.dispatch('auth/setCurrentUser', tokenData.payload);
      this.$store.dispatch('application/loadMenuItems');
      this.$store.dispatch('person/fetchPerson', this.person.id);
    },
  },
  computed: {
    user() {
      return this.$store.getters['auth/currentUser'];
    },
    person() {
      return this.$store.getters['person/person'];
    },
    reprovedEntity() {
      return this.selectedEntity.status === 'not_approved';
    },
    formWasEdited() {
      const person = this.pick(this.person, ['profile', 'name', 'cpf_cnpj', 'cellphone']);
      return !this.isEqual(this.formData, person);
    },
    canEdit() {
      return !this.edit && this.selectedEntity.status !== 'waiting_approval';
    },
  },
  watch: {
    person() {
      this.loadPersonData();
    },
  },
};
</script>

<style lang="scss" scoped>
  .no-padding{
    padding: 0 !important;
    & .v-card__text{
      padding: 0 !important;
    }
  }
</style>
