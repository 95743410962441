var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { class: { "pa-0": !_vm.isCompleteInfoComponent }, attrs: { fluid: "" } },
    [
      _c(
        "v-form",
        {
          ref: "form",
          attrs: { "lazy-validation": "" },
          model: {
            value: _vm.valid,
            callback: function ($$v) {
              _vm.valid = $$v
            },
            expression: "valid",
          },
        },
        [
          _c(
            "v-layout",
            { attrs: { "justify-start": "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c("header", { staticClass: "headline" }, [
                    _c(
                      "h5",
                      { staticClass: "title" },
                      [
                        _c("v-icon", { staticClass: "mr-2" }, [
                          _vm._v("mdi-image-outline"),
                        ]),
                        _vm._v(" Logo da Empresa\n          "),
                      ],
                      1
                    ),
                  ]),
                  _vm.person.logo.url !== null
                    ? _c(
                        "div",
                        { staticClass: "text-xs-center" },
                        [
                          _c("img", {
                            attrs: { src: _vm.person.logo.url, alt: "" },
                          }),
                          _c(
                            "v-tooltip",
                            {
                              attrs: { top: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on }) {
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            {
                                              staticClass: "remove-logo",
                                              attrs: {
                                                color: "red",
                                                small: "",
                                                icon: "",
                                                flat: "",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.destroyLogo()
                                                },
                                              },
                                            },
                                            on
                                          ),
                                          [
                                            _c("v-icon", [
                                              _vm._v(
                                                "\n                  mdi-close-circle-outline\n                "
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                1559378993
                              ),
                            },
                            [_c("span", [_vm._v("Remover Logo")])]
                          ),
                        ],
                        1
                      )
                    : _c(
                        "v-card",
                        { staticClass: "mb-5" },
                        [
                          _c(
                            "v-container",
                            { attrs: { "grid-list-lg": "" } },
                            [
                              _c(
                                "v-layout",
                                { attrs: { row: "", "align-center": "" } },
                                [
                                  _c(
                                    "v-flex",
                                    { attrs: { xs12: "" } },
                                    [
                                      _c("file-upload", {
                                        attrs: {
                                          label: "Arquivo de logo",
                                          allowedExtensions: ["png", "jpg"],
                                          extensionErrorMessage:
                                            "Apenas os formatos .PNG ou .JPG são permitidos",
                                          isUploadingFile: _vm.isUploadingFile,
                                          uploadError: _vm.uploadError,
                                          file: _vm.logo,
                                          filename: _vm.title,
                                          uploadFunction: _vm.uploadFile,
                                          removeFunction: _vm.removeFile,
                                        },
                                        on: {
                                          "update:file": function ($event) {
                                            _vm.logo = $event
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }